import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import Hero from './components/hero/hero';
import Manifesto from "./components/manifesto/manifesto";
//import Posts from "./components/posts/posts";
//import Post from "./components/post/post";


function App() {
  return (
      <Router>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Hero />} />
            <Route path="/manifesto" element={<Manifesto />} />
            {/*<Route path="/posts" element={<Posts />} />
            <Route path="/posts/:id" element={<Post />} />*/}
          </Routes>
      </Router>
  );
}

export default App;
