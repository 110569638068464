import React, { useState } from 'react';
import { Link } from 'react-router-dom'

// local imports
import './navbar.css'

function Navbar() {
    const [height, setHeight] = useState("0%");
    const showOverlay = () => setHeight("100%");
    const hideOverlay = () => setHeight("0%");

    return (
        <>
            <div id="myNav" className="overlay" style={{height: height}}>
                <i className="fas fa-times closebtn" aria-hidden="true" onClick={hideOverlay}></i>
                <div className="overlay-content">
                    <Link to="/" onClick={hideOverlay}>Home</Link>
                    <Link to="/manifesto" onClick={hideOverlay}>Manifesto</Link>
                    <a href="https://twitter.com/thejacobdonahue">Tweet Me</a>
                </div>
            </div>
            <nav className="navbar">
                <div className="container">
                    <ul className="nav" onClick={showOverlay}>
                        <li>
                            <i className="fa fa-bars" aria-hidden="true"></i>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
