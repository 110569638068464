import React from 'react';
import './manifesto.css';

function Manifesto() {
  return (
    <div className="manifesto">
        <div className="manifesto-container">
          <div className="interest-header">
            <h1>Welcome to the Information Age</h1>
            <p>
              The Information Age has best been described by the English playwright
              Tom Stoppard in his 1993 play, Arcadia, "The future is disorder.
              A door like this has cracked open five or six times since we got up on our hind legs.
              It is the best possible time to be alive, when almost everything you thought you knew is wrong."
            </p>
            <p>
              We are in the midst of the most sweeping revolution in human history.
              Rapidly evolving information technologies such as AI, blockchain
              and telepresence are unveiling our misconceptions
              of the present and reimagining the boundaries in which the future must lie.
              It is an opportunity not to be wasted.
            </p>
            <h2>Primary and Related Interests</h2>
            <p>
              _donahue has three primary and related interests:
            </p>
            <div>
              <h4>Educate</h4>
              <p>
                Continue to educate all counterparts regarding the
                transformative implications of the Information Age.
              </p>
            </div>
            <div>
              <h4>Innovate</h4>
              <p>
                Invest in, develop and support innovation.
              </p>
            </div>
            <div>
            <h4>Prosper</h4>
            <p>
              Produce outstanding financial returns for my counterparts and I.
            </p>
            </div>
         
          </div>
        </div>
    </div>
  )
}

export default Manifesto