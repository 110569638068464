import React from 'react';
import './hero.css';
import { Link } from 'react-router-dom'; 

function Hero() {

  return (
    <header className="hero">
      <div className="custom-container">
        <div className="hero-text">
          <h1>
            donahue_
          </h1>
          <Link to="/manifesto">
            <small>Manifesto <i className="fa fa-arrow-right" aria-hidden="true"></i></small>
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Hero;
